<template>
  <b-card
    class="salesoutbound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="salesoutboundForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单编号"
            label-for="outbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_no"
              size="sm"
              v-model="salesoutbound.outbound_no"
              readonly=""
            />
          </b-form-group>
        </b-col>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="销售单ID"-->
<!--            label-for="order_id"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="order_id"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.order_id"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单编号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="salesoutbound.order_no"
              readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="物流方式"
            label-for="delivery_type"
            label-size="sm"
            class="mb-1 required"
          >
            <v-select
                id="delivery_type"
                :options="getCodeOptions('delivery_method_sale')"
                :clearable="true"
                v-model="deliveryType"
                @input="changeSelect('delivery_type',$event)"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="运输方式"
            label-for="transport_type"
            label-size="sm"
            class="mb-1 required"
          >
            <v-select
                id="transport_type"
                :options="getCodeOptions('transport_type')"
                :clearable="true"
                v-model="transportType"
                @input="changeSelect('transport_type',$event)"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="memo"
              size="sm"
              v-model="salesoutbound.memo"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">附件</div>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attach"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="salesoutbound.loaded" :theme="'files'"
                               :attachment_id="'attach'"
                               :id="salesoutbound.attach"
                               :object_type="'sales_outbound'"
                               :object_id="salesoutbound.outbound_id"
                               @change="onUploaded"
            />
            <!--            <b-form-input-->
            <!--              id="attach"-->
            <!--              size="sm"-->
            <!--              v-model="salesoutbound.attach"-->
            <!--            />-->
          </b-form-group>
        </b-col>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="出库状态  sale_outbound_status"-->
<!--            label-for="status"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="status"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.status"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="状态"-->
<!--            label-for="state"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="state"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.state"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="创建时间"-->
<!--            label-for="add_time"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="add_time"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.add_time"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="创建人"-->
<!--            label-for="creator"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="creator"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.creator"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="修改时间"-->
<!--            label-for="modify_time"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="modify_time"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.modify_time"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="修改人"-->
<!--            label-for="updator"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="updator"-->
<!--              size="sm"-->
<!--              v-model="salesoutbound.updator"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->

        <b-col md="12">
          <div class="inner-card-title">销售出库单明细</div>
        </b-col>

        <b-col md="12" >
          <sales-outbound-item-list
               :outbound_id="salesoutbound.outbound_id" v-if="itemListShowFlag" ref="itemList" v-on:table="fromChildren" >
          </sales-outbound-item-list>
        </b-col>

                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesoutboundStore from './salesoutboundStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import SalesOutboundItemList from "@/views/apps/salesoutbounditem/SalesOutboundItemList";
import salesoutbounditemStore from "@/views/apps/salesoutbounditem/salesoutbounditemStore";

export default {
  components: {
    SalesOutboundItemList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      ids: ref(0),
      orderid:ref(0),
      salesoutbound: {},
      itemListShowFlag:false,
      deliveryType:{},
      transportType:{},
      salesoutbounditem:[],
    }
  },
  props:{
    outbound_id:{
      type:Number,
      default:0
    }

  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesoutbound')) store.registerModule('salesoutbound', salesoutboundStore)
    if (!store.hasModule('salesoutbounditem')) store.registerModule('salesoutbounditem', salesoutbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesoutbound')) store.unregisterModule('salesoutbound')
      if (store.hasModule('salesoutbounditem')) store.unregisterModule('salesoutbounditem')
    })

    const edit = function() {
      if (this.orderid!==undefined&&this.id===0){
        store.dispatch('salesoutbound/add', {id:this.orderid}).then(res => {
          this.salesoutbound = res.data.data
          if (this.salesoutbound.outbound_id!==undefined){
            this.itemListShowFlag = true
          }
          this.deliveryType=getCode("delivery_method_sale",this.salesoutbound.delivery_type)
          this.transportType=getCode("transport_type",this.salesoutbound.transport_type)
        })
      }
      if (this.id!==undefined&&this.id!==0){
        store.dispatch('salesoutbound/edit', {id:this.id}).then(res => {
          if (res.data.code==1){
            toast.error(res.data.data)
            this.$router.push({ name: 'apps-salesoutbound-list'});
          }
          this.salesoutbound = res.data.data
          if (this.salesoutbound.outbound_id!==undefined){
            this.itemListShowFlag=true
          }
          this.deliveryType=getCode("delivery_method_sale",this.salesoutbound.delivery_type)
          this.transportType=getCode("transport_type",this.salesoutbound.transport_type)

        })
      }

    }

    const view = function() {
      store.dispatch('salesoutbound/view', {id: this.id}).then(res => {
        this.salesoutbound = res.data.data

      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      if (this.salesoutbound.delivery_type === undefined || this.salesoutbound.delivery_type === "") {
        toast.error("请选择 物流方式!")
        return false
      }
      if (this.salesoutbound.transport_type === undefined || this.salesoutbound.transport_type === "") {
        toast.error("请选择 运输方式!")
        return false
      }
      for (let i = 0; i < this.salesoutbounditem.length; i++) {
        if (this.salesoutbounditem[i].is_sel==1){
          if (this.salesoutbounditem[i].otherwarehouse_name==""||this.salesoutbounditem[i].store_name==""){
            toast.error("请填写勾选产品的目的仓库和店铺!")
            return false
          }
        }
      }

      store.dispatch('salesoutbound/save', this.salesoutbound).then(res => {
        if (res.data.code===1){
          toast.error(res.data.data)
          this.$refs.itemList.refetchData()
        }else {
          toast.success('数据已保存!')
          this.salesoutbound.outbound_id = res.data.data.outbound_id
          this.itemListShowFlag = true
          for (let i = 0; i < this.salesoutbounditem.length; i++) {
            saveSalesOutboundItem(this.salesoutbounditem[i])
            // this.$refs.itemList.refetchData()
          }
        }
        // this.$router.push({ name: 'apps-salesoutbound-list'});
      })


      // this.$refs.itemList.refetchData()
    }

    const saveSalesOutboundItem = function (result) {
      store
          .dispatch('salesoutbounditem/updateOrderItem', {
            itemid: result.outbounditem_id,
            qty: result.qty,
            issel: result.is_sel,
            warehouseid: result.otherwarehouse_id,
            storeid: result.store_id,
            deliverytime: second(result.delivery_time),
            arrivaltime: second(result.arrival_time),
          })
          .then(res => {
            if (res.data.code==0){
              toast.success(result.name+'数据已保存!')
            }else {
              toast.error(result.name+'保存失败!')
            }

          })
          .catch((e) => {
            toast.error(result.name+'数据保存失败!')
          })
    }

    const changeSelect = function(key,event) {
      this.salesoutbound[key] = event.value
    }

    const onUploaded = function (id, attachment,result) {
      this.salesoutbound[id] = result
    }

    const fromChildren = function (val) {
      this.salesoutbounditem = val
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeSelect,
      onUploaded,
      fromChildren,
      saveSalesOutboundItem,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.orderid = this.$route.query.orderid || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>